import React, { useContext, useEffect, useState, useRef, useCallback, useMemo } from 'react'
import Header from './Header';
import Login from '../Login/Login';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"
import swal from 'sweetalert';
import Swal from 'sweetalert2';

import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { SocketContext } from './SocketProvider';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
// import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { DataGrid, GridRowsProp, GridColDef,ruRU } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box, Grid,FormControl,Select,MenuItem,FormHelperText,
  Typography, Stack,Paper, LinearProgress, Checkbox,ToggleButtonGroup,ToggleButton,FormControlLabel,
  Button,
} from "@mui/material";
import AddEditPromocode from './AddEditPromocode';

export const  Settings = () => {

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });
  const [justOwn, setJustOwn] = useState(true);
  const [sany,setSany] = useState(0);
  const [rowCountState, setRowCountState] = React.useState(sany);
  const socket = useContext(SocketContext);
  const admin = JSON.parse(sessionStorage.getItem('user'));
  var innerlogs = [];
  const [logs, setLogs] = useState([]);
  const token2 = 'mesanatoken';
  const [loading,setLoading] = useState(false);
  const token = sessionStorage.getItem('accessToken');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [promo,setPromo] = useState();
  const [promoUseAmount, setPromoUseAmount] = useState(0);
  const [promoType, setPromoType] = useState(0);
  const [promoAmount,setPromoAmount] = useState();
  const [tockas, setTockas] = useState([]);
  const [value, setValue] = useState();
  const [selectedTockaId,setSelectedTockaId] = useState(0);
  const [isFiltered,setIsFiltered] = useState(false);
  const [offset,setOffset] = useState(0);
  const selectedTockaId2 = useRef(null);
  const [promocodes, setPromocodes] = React.useState([]);
  const [selectedTocka,setSelectedTocka] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10
  })
  const [rows, setRows] = React.useState([]);
  const [prodycty, setProducty] = React.useState([]);
  const [summa,setSumma] = useState(0);
  const [selectedPromocode, setSelectedPromocode] = useState();
  const [selectedPromocodeId, setSelectedPromocodeId] = useState(0);
  const [open, setOpen] = useState(false);
  const days = [
	'ПН','ВТ','СР','ЧТ','ПТ','СБ','ВС'
  ];
  const MyToggleButton = styled(ToggleButton)(({ selectedColor }) => ({
	"&.Mui-selected": {
	  color: "white",
	  backgroundColor: selectedColor,
	  fontWeight: 'bold'
	},
	"&.Mui-selected:hover":{
	  backgroundColor: '#29b6f6',
	}
  }));

  const getBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color, mode) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({

	'& .MuiDataGrid-columnHeaderTitle': {
		textOverflow: "clip",
		whiteSpace: "break-spaces",
		lineHeight: 1,
	},

    '& .super-app-theme--Open': {
      backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.info.main,
            theme.palette.mode,
          ),
        },
      },
    },
    '& .super-app-theme--Filled': {
      backgroundColor: getBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.success.main,
            theme.palette.mode,
          ),
        },
      },
    },
    '& .super-app-theme--PartiallyFilled': {
      backgroundColor: getBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.warning.main,
            theme.palette.mode,
          ),
        },
      },
    },
    '& .super-app-theme--Rejected': {
      backgroundColor: getBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(
            theme.palette.error.main,
            theme.palette.mode,
          ),
        },
      },
    },
  }));

  const columns = [
    { field: 'col1', headerName: '№', width: 10 ,sortable: false,headerAlign: 'center' },
    { field: 'col2', headerName: 'Промокод', width: 100 ,sortable: false,headerAlign: 'center', renderCell: (params) => (
      
      <Typography variant="h5" color="#000" sx={{backgroundColor:'transparent', fontWeight:'bold'}} >{params.value.promocode}</Typography> 
      
    ) },
    { field: 'col3', headerName: 'Вид промокода', width: 100 ,sortable: false, headerAlign: 'center',
		renderCell: (params) =>(
			<Stack width='100%' direction='column'>
			<Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value}</Typography>
			{/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
		</Stack>
		)
	},
    { field: 'col4', headerName: 'Зна-\nчение', width: 60 ,sortable: false ,headerAlign: 'center',
		renderCell: (params) =>(
			<Stack width='100%' direction='column'>
			<Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value}</Typography>
			{/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
		</Stack>
		)
	},
    { field: 'col5', headerName: 'Использование', width: 120 ,sortable: false ,headerAlign: 'center'},
    { field: 'col6', headerName: 'Исполь\nзовано', width: 70 ,sortable: false ,headerAlign: 'center',
		renderCell: (params) =>(
			<Stack width='100%' direction='column'>
			<Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value}</Typography>
			{/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
		</Stack>
		)
	},
	{ field: 'col14', headerName: 'На Сумму\nСо Скидкой', width: 100 ,sortable: false ,headerAlign: 'center',
		renderCell: (params) =>(
			<Stack width='100%' direction='column'>
			<Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.summa}</Typography>
			<Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.skidka}</Typography>
		</Stack>
		)
	},

    { field: 'col7', headerName: 'Создано', width: 80 ,sortable: false,headerAlign: 'center', renderCell: (params) =>(
      <Stack width='100%' direction='column'>
        <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.sene}</Typography>
         <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography>
       </Stack>
    )},
    { field: 'col8', headerName: 'Начало', width: 80 ,sortable: false, headerAlign: 'center',renderCell: (params) =>(
      <Stack width='100%' direction='column'>
        <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.sene}</Typography>
         {/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
       </Stack>
    )},
    { field: 'col9', headerName: 'Конец', width: 80 ,sortable: false, headerAlign: 'center',renderCell: (params) =>(
      <Stack width='100%' direction='column'>
        <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.sene}</Typography>
         {/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
       </Stack>
    ) },
    { field: 'col10', headerName: 'На N раз', width: 60 ,sortable: false ,headerAlign: 'center',
		renderCell: (params) =>(
			<Stack width='100%' direction='column'>
			<Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value}</Typography>
			{/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
		</Stack>
		)
	
	},
    { field: 'col11', headerName: 'Услуга', width: 120 ,sortable: false , align:'center',headerAlign: 'center',
	renderCell: (params) =>(
		<Box component='div' lineHeight={'1.37'} whiteSpace="normal" width='100%' height='100%' direction='column' sx={{wordWrap: 'break-word',display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center',justify:'center'}}>
		<Typography  width='100%' variant='h7' color="#000" sx={{backgroundColor:'transparent'}} >{params.value}</Typography>
		{/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
	</Box>
	)

   },
  	{ field: 'col12', headerName: 'Дни активности', width: 240, sortable: false, align:'center', headerAlign: 'center',
   		renderCell: (params) =>(

			
			<Stack direction='column' width='100%' height='100%'  sx={{display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center',justify:'center' }} >
				<ToggleButtonGroup
					
					value={params.value.denNedeli}
					// onChange={handleDenChange}
					aria-label="device"
					sx={{height:20,mt:-0.5 }}
				>
					{	days.map((day)=>{
						return(<MyToggleButton value={day} aria-label={day} 
						selectedColor = '#4fc3f7'
						sx={{width:30, 
							
							
							}}>
						{day}
					</MyToggleButton>)
					})}

				</ToggleButtonGroup>
			</Stack>
			
		)
	},
	{ field: 'col13', headerName: 'Промежуток времени', width: 100 ,sortable: false ,headerAlign: 'center',
		renderCell: (params) =>(
			<Stack width='100%' direction='column'>
			<Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value == '00:00-00:00' ? 'весь день' : params.value}</Typography>
			{/* <Typography variant="h7" color="#000" sx={{backgroundColor:'transparent'}} >{params.value.wremya}</Typography> */}
		</Stack>
		)
	
	},
	{ field: 'col15', headerName: 'Удалить', width: 100 ,sortable: false ,headerAlign: 'center',
		renderCell: (params) =>(

			admin.admin_type == 0 || admin.admin_type == 6 ? '' : 
			<IconButton hidden
				sx={{
				backgroundColor:'#f57c00',
				'&:hover':{
					backgroundColor:'#f57c00'
				}
				}}
				color="primary" component="label" onClick={()=>{
				// //console.log('BASDYM ' + params.value.billid + ' we ' + params.value.inn);
				Swal.fire({
				title: "Вы уверены?",
				text: "Вы уверены что хотите УДАЛИТЬ промокод "+params.value.promocode.promocode+" !",
				icon: "question",
				showDenyButton: true,
				confirmButtonText: 'Да',
				denyButtonText: 'Отмена',
				})
				.then((willDelete) => {
				if (willDelete.isConfirmed) 
					{
					//handleTockaSettingsClick(selectedTocka);
					//console.log('SOGLASEN');
					axios.post(
						'http://46.17.250.209:8081/disable/promocode',
						{
						pid:params.value.promocode.id,
						token:token2,
						what:params.value.promocode.isactive == true ? 'false' : 'true'
						}
					).then(res => {
						if (res.data.success == 1){
							Swal.fire("УСПЕХ","Данные успешно обновлены!", "success",{
							button:'OK'
							})
							.then((value) => {
							//fetchProductsByTocka(selectedTocka.id);
							fetchPromocodesByTocka(0);
							});
						} else {
							Swal.fire("ОШИБКА", res.data.message, "error", {
							button: 'OK'
							});
							
						}
					})
					} else 
					{
					//console.log('OTMENA');
					}
				});
				
			}}>
				
				<RemoveCircleIcon sx={{color:'#fff' , fontSize: 15, width:20,height:20 }}/>
			</IconButton>
			)
		
	
	},
  ];

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      sany !== undefined ? sany : prevRowCountState,
    );
  }, [sany, setRowCountState]);

  const handleChange = (event) => {
    setValue(event.target.value);
    setSelectedTockaId(event.target.value);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    elevation: 0,
    color: theme.palette.text.secondary,
  }));

  const Item2 = styled(Paper)(({ theme }) => ({
    backgroundColor: '#501A2027',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    elevation: 0,
    // textAlign: 'center',
    color: theme.palette.text.secondary,
    
  }));

  const handleFilterClicked = (event) =>{
    setIsFiltered(true);
    setOffset(0);
    //fetchPromocodes();
    

    if (selectedTockaId != null){
      console.log('tocka selected ' + selectedTockaId);
      var tck =  tockas.find(element => element.id == selectedTockaId);
      console.log('admin id = ' + tck.tocka_admin_id);
      fetchPromocodes2(tck.tocka_admin_id);
      
    } else {
      console.log('tocka not selected show all');
    }

  }

  const findArrayElementByEdit = (tocka_admin_id) =>{
    return tockas.find((element) => {
      return element.id === tocka_admin_id;
    })
  }

  const handleAddClicked = async (event) =>{
    var tocka2 = Array.isArray(logs) && logs.find(el => el.promocode === promo);

    if (tocka2 != undefined){
      swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА, ДАННЫЙ ПРОМОКОД СУЩЕСТВУЕТ', "error", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
      });
    } else {
      if (!isNaN(+promoAmount) && !promoAmount.includes('.')){
        const { data } = await axios.post(
          "http://46.17.250.209:8081/add/new/promocode",{
            createdby:admin.id,
            token:token2,
            promocode:promo,
            promouseamount:promoUseAmount,
            promotype:promoType,
            startime:startDate,
            endtime:endDate,
            promoamount:promoAmount,
            tockaid:selectedTockaId
          }
        )
        
        if (data.success == 1){
          swal("УСПЕХ", 'ДАННЫЕ НОВОГО ПРОКОДА ДОБАВЛЕНЫ ', "success", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
          });
        } else {
          swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА ', "error", {
            buttons: false,
            timer: 2000,
          })
          .then((value) => {
          });
        }
    
        fetchPromocodes();
      } else {
        swal("ОШИБКА", 'ОШИБКА ПРИ ДОБАВЛЕНИИ НОВОГО ПРОМОКОДА, ОБЪЕМ СКИДКИ ДОЛЖЕН БЫТЬ ЦЕЛЫМ ЧИСЛОМ ', "error", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
        });
      }
    }
  }

  const handleCloseFilter = ()=>{

    setStartDate(new Date);
    setEndDate(new Date);
    setIsFiltered(false);
    setOffset(0);
    fetchPromocodes();
    setSelectedTockaId(0)
  }

  useEffect(()=>{
    selectedTockaId2.current = typeof selectedTockaId != 'undefined' ? selectedTockaId : null;
    console.log('SELECTED TOCKA ISLEDI');
    // fetchSelectedTockaData(selectedTockaId);
    // fetchSelectedTockaProducts(selectedTockaId)
    setSelectedPromocodeId(0)
  }, [selectedTockaId]);

  useEffect(() => {
    console.log('PROMOCODES ');
    fetchProducts();
    // socket.emit("test", 'PROMOS'); 
    return () => {
   };
  },[]);

  const fetchProducts = async () => {
    if (admin.admin_type == 1 || admin.admin_type == 2 || admin.admin_type == 4){
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/for/admin/new",{
          admin_id:admin.id,
          token:token2
        }
      )
      console.log(' HANT ' + data.tockas.length);
      const products = data;
      setTockas(products.tockas);
      setSelectedTockaId(data.tockas[0].id);
      console.log(' HANT2 ' + selectedTockaId);
      // if (products.tockas.length == 1){
        setSelectedTocka(products.tockas[0]);
      // }
      // innertockas = products.tockas;
      
    } else if (admin.admin_type == 6) {
		const { data } = await axios.post(
			"http://46.17.250.209:8081/get/tockas/for/sub/admin/new",{
			  admin_id:admin.id,
			  token:token2
			}
		  )
		  console.log(' HANT ' + data.tockas.length);
		  const products = data;
		  setTockas(products.tockas);
		  setSelectedTockaId(data.tockas[0].id);
		  console.log(' HANT2 ' + selectedTockaId);
		  // if (products.tockas.length == 1){
			setSelectedTocka(products.tockas[0]);
		  // }
		  // innertockas = products.tockas;
	} 


    else {

      axios.post(
        "http://46.17.250.209:8081/get/tockas/by/admin/new",{
          admin_id:admin.id,
          token:token2
        }
      ).then(res=>{
        const products = res.data;
        // innertockas = products.tockas;
        setTockas(products.tockas);
        setSelectedTockaId(products.tockas[0].id);
        // setIsLoaded(true);
          if (products.tockas.length > 0){
            setSelectedTocka(products.tockas[0]);
            // setProducts(products.tockas[0].products);
            // setOrange(products.tockas[0].orange_data);
          } 
          
        }

      )
     }

    
  };
  
  const onChagePromo = (event) => {
      setPromo(event.target.value);
  }

  const onChagePromoUseAmount = (event) => {
    setPromoUseAmount(event.target.value);
  }

  const onChagePromoType = (event) => {
    setPromoType(event.target.value);
  }

  const onChagePromoAmount = (event) => {
    setPromoAmount(event.target.value);
  }

  const fetchTockasByDate = async (of3) => {
    
      var of = of3 * 50;
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/logs/by/tocka/date",{
          tockaid:selectedTockaId,
          token:token2,
          startdate:moment(startDate).format("YYYY-MM-DD"),
          enddate:moment(endDate).format("YYYY-MM-DD"),
          offset:of
        }
      )
      
      setLogs(data.logs);
      if (data.logs.length == 0){
        swal("ВНИМАНИЕ", 'NO MORE ITEMS ', "warning", {
          buttons: false,
          timer: 2000,
        })
        .then((value) => {
          var of2 = of3 - 1;
          setOffset(of);
        });
      } else {
        
      }
    
  };

  const fetchTockas = async () => {

    if (admin.admin_type == 1 || admin.admin_type == 3){
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/for/admin",{
          admin_id:admin.id,
          token:token2
        }
      )
      
      setTockas(data.tockas);
      //setSelectedTockaId(data.tockas[0].id);
      fetchPromocodes();
    } else {
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tockas/by/admin",{
          admin_id:admin.id,
          token:token2
        }
      )
      var tockas = data.tockas;
      setTockas(tockas);
      setSelectedTockaId(tockas[0].id);
      fetchPromocodes();
    }

    
  };


  const fetchPromocodes = async () => {

    if (admin.admin_type == 1){
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tocka/promocodes/for/admin",{
          admin_id:admin.id,
          token:token2
        }
      )
      
      setLogs(data.promos);
    } else {
      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tocka/promocodes/by/tocka",{
          admin_id:admin.id,
          token:token2
        }
      )
      var tockas = data.promos;
      setLogs(tockas);
    }


  };

  const fetchPromocodes2 = async (admin_id2) => {

    // if (admin.admin_type == 1){
    //   const { data } = await axios.post(
    //     "http://46.17.250.209:8081/get/tocka/promocodes/for/admin",{
    //       admin_id:admin_id2,
    //       token:token2
    //     }
    //   )
      
    //   setLogs(data.promos);
    // } else {
      //tolkoswoi

      const { data } = await axios.post(
        "http://46.17.250.209:8081/get/tocka/promocodes/by/admin",{
          admin_id:admin_id2,
          token:token2,
          tolkoswoi:1
        }
      )
      var tockas = data.promos;
      setLogs(tockas);
    // }


  };

  const handleTockaChange = (event) => {
    // setValue(event.target.value);
    setPromocodes([]);
    setSelectedTocka(tockas.filter((tocka)=>{return tocka.id === event.target.value})[0]);
    setSelectedTockaId(event.target.value);
  };

  
  const setActive = async (promoid, isactive) => {

    const { data } = await axios.post(
      "http://46.17.250.209:8081/activate/promocode",{
        promoid:promoid,
        token:token2,
        isactive:!isactive
      }
    )
    
    
    fetchPromocodes();

  }

  const onPromocodeDoubleCLick = useMemo((params,event) => {
    // console.log('Clicked Item : ');
    // setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
  });


  const fetchPromocodesByTocka = async (of3) => {

    setPromocodes([])
    // setLoading(true)
    setPageState(old => ({ ...old, isLoading: true }))

    var of = 0;
    if (of3 > 0) {
      of = (of3 - 1) * 50;
    } 
    
    // setRows([]);

    console.log('SELECTED TOCKAID ' + selectedTockaId);
    // //console.log('srtart date ' + dayjs(startDate).format("YYYY-MM-DD"));
    // //console.log('enddate date ' + dayjs(endDate).format("YYYY-MM-DD"));

    const { data } = await axios.post(
      "http://46.17.250.209:8081/get/promocodes/by/tocka",{
        tockaid:selectedTockaId,
        token:token2,
        // startdate:dayjs(startDate).format("YYYY-MM-DD"),
        // enddate:dayjs(endDate).format("YYYY-MM-DD"),
        offset:of,
        justmy: justOwn
      }
    )
    
    setPromocodes(data.promocodes);



      var rows2 = [];
      var ind2 = 0;
      if (of3 > 0) {
        ind2 = (of3 - 1) * 50
      }
      
      // //console.log('LOGS COUNT ' + data.sales.length + ' IND ' + ind2);
      

      data.promocodes.map((promocode,ind) => {
        var ind3 = ind2 + 1 + ind;
        // //console.log('DATETIME ' + new Date(Date.UTC(moment(tocka.sold_at).year, moment(tocka.sold_at).month, moment(tocka.sold_at).day, moment(tocka.sold_at).hours, moment(tocka.sold_at).minutes, moment(tocka.sold_at).seconds)))
        
        var sagat = moment(promocode.created_at).add(3,'hours')

        var promotype = ''
        switch (promocode.promocodetype) {
          case 0:
            promotype = 'процент'
            break;
          case 1:
            promotype = 'сумма'
            break;
        
          default:
            break;
        }

        var promotype2 = ''
        switch (promocode.reuseable) {
          case 0:
            promotype2 = 'одноразовый'
            break;
          case 1:
            promotype2 = 'многоразовый'
            break;
        
          default:
            break;
        }

        var usluga = '';
        switch (promocode.promocodefor) {
          case 0:
            usluga = 'все услуги'
            break;
          case 1:
            usluga = 'стирка'
            break;
          case 2:
            usluga = 'сушка'
            break;
		  case 3:
            usluga = 'сушка + стирка'
            break;
          case 4:
            usluga = 'стирка + сушка (обуви)'
            break;
          case 5:
            usluga = 'стирка обуви'
            break;
		  case 6:
            usluga = 'сушка обуви'
            break;
		  case 7:
            usluga = 'стирка + стирка обуви'
            break;
		case 8:
            usluga = 'сушка + сушка обуви'
            break;
		case 9:
            usluga = 'стирка/сушка + стирка/сушка обуви'
            break;
          default:
            break;
        }

		//<MenuItem value={7}>Стирка + Стирка (обуви)</MenuItem>
		// <MenuItem value={8}>Сушка + Сушка (обуви)</MenuItem>
		// <MenuItem value={9}>Стирка/Сушка + Стирка/Сушка (обуви)</MenuItem>

        var sene = moment.utc(sagat).format("DD.MM.YYYY kk:mm:ss").split(' ')[0];
        var wremya = moment.utc(sagat).format("DD.MM.YYYY kk:mm:ss").split(' ')[1];
        console.log('IND ' + moment.utc(sagat).format("DD.MM.YYYY kk:mm:ss").split(' ')[0]);

		var days2 = []
		for (var i = 0; i < promocode.workingdays.length; i++) {
		  if (promocode.workingdays[i] == '1'){
			days2.push(days[i])
		  }
		}

		console.log('HANY ');
		console.log(promocode.workinghours);


        rows2.push({ id: promocode.id, 
          col1: ind3, 
          col2: {promocode: promocode.promocode, isactive: promocode.isactive}, 
          col3: promotype, 
          col4: promocode.value,  
          col5: promotype2, 
          col6: promocode.used,  
          col7: {sene: moment.utc(sagat).format("DD.MM.YYYY kk:mm:ss").split(' ')[0], wremya: moment.utc(sagat).format("DD.MM.YYYY kk:mm:ss").split(' ')[1]}, 
          col8: {sene: moment.utc(moment(promocode.startdate).add(3,'hours')).format("DD.MM.YYYY kk:mm:ss").split(' ')[0], wremya: moment.utc(moment(promocode.startdate).add(3,'hours')).format("DD.MM.YYYY kk:mm:ss").split(' ')[1]}, 
          col9: {sene: moment.utc(moment(promocode.expiredate).add(3,'hours')).format("DD.MM.YYYY kk:mm:ss").split(' ')[0], wremya: moment.utc(moment(promocode.expiredate).add(3,'hours')).format("DD.MM.YYYY kk:mm:ss").split(' ')[1]}, 
          col10: promocode.usetimes,
          col11: usluga,
		  col12: {denNedeli:days2},
		  col13: promocode.workinghours,
		  col14: {summa:promocode.summa, skidka:promocode.skidka},
		  col15: {promocode:promocode},
          isactive: promocode.isactive,
          promocodetype: promocode.promocodetype,
          promocode: promocode
        });

      /**
       * { field: 'col1', headerName: '№', width: 10 ,sortable: false },
    { field: 'col2', headerName: 'Промокод', width: 120 ,sortable: false },
    { field: 'col3', headerName: 'Вид промокода', width: 120 ,sortable: false },
    { field: 'col4', headerName: 'Значение', width: 120 ,sortable: false },
    { field: 'col5', headerName: 'Использование', width: 120 ,sortable: false },
    { field: 'col6', headerName: 'Использовано', width: 120 ,sortable: false },
    { field: 'col7', headerName: 'Создано', width: 120 ,sortable: false },
    { field: 'col8', headerName: 'Начало', width: 120 ,sortable: false },
    { field: 'col9', headerName: 'Конец', width: 120 ,sortable: false },
    { field: 'col10', headerName: 'На N раз', width: 120 ,sortable: false },
    { field: 'col11', headerName: 'Услуга', width: 120 ,sortable: false , align:'center',
       * 
       */
        
      setLoading(false)

    }
    )

    setPageState(old => ({ ...old, isLoading: false, data: data.promocodes, total: data.sany }))
    setLoading(false)

    setPromocodes(rows2);

    // //console.log('SET ROWS ISHLEMELI');
    //setRows(rows2);
    //setRows([]);
    // setRows([...rows, {rows2}]);
    

    // setSumma(data.cost);
    setSany(data.sany);
    
    if (data.promocodes.length == 0){
      swal("ВНИМАНИЕ", 'НИЧЕГО НЕ НАЙДЕНО', "warning", {
        buttons: false,
        timer: 2000,
      })
      .then((value) => {
        var of2 = of3 - 1;
        setOffset(of);
      });
    } else {
    }

    
  
};

async function checkPromocode(promocode) {


    const { data } = await axios.post(
      "http://46.17.250.209:8081/check/promocode",{
        promocode:promocode,
        token:token2,
      }
    )
    console.log('SANY22 ' + data.promos);
	if (data.promos == 0) {
		console.log('FUNC true');
		return true;
	} else {
		console.log('FUNC false');
		return false;
	}

    
  
};

const getRandomPin = (chars, len)=>[...Array(len)].map(
  (i)=>chars[Math.floor(Math.random()*chars.length)]
).join('');

const genPromocode = (sany) => {
  var proms = [], i = 0, len = sany;

  //8343
  while (i < len) { 
    var hany = getRandomPin('0123456789',4)
	
	var hany2 = true 
	checkPromocode(hany).then((result)=>{
		hany2 = result
		console.log('THEN ' + hany2);	
	})

	if (hany2) {
		console.log('SALDYM ' + hany);
		proms.push(hany)
		++i
	} else {
		console.log('BAR UJE ' + hany);
	}

	
    
  };
    // console.log('GEN IN PROMOS ' + getRandomPin('0123456789',4));
    return proms;
}

const handleCancel = () => {
  setSelectedPromocodeId(0)
  setOpen(false)
};
  
  if(!token ) {
    return (<Login  />)
  }

  return (
    <div>
    <Header selected = {3}/>
    <Box sx={{ flexGrow: 1 }} margin = {2}>

		{ typeof selectedPromocode != 'undefined' ? 
			<AddEditPromocode open={open} onClose={handleCancel} selectedPromocode={selectedPromocode} selectedTocka={selectedTocka} selectedPromocodeId={selectedPromocodeId} genPromocode = {genPromocode} adminid={admin.id} sx={{height:550}}/>
			: ''}
      
      
      

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}  >

      	<Grid item xs={12} sm={6} md={3}  >
            

        <Grid item xs={12} sm={12} md={12} >
          <Item2 elevation='0' sx={{
      
              height: '100%',
              backgroundColor: '#eafefd',
              // opacity: [0.3, 0.4, 0.3],
              '&:hover': {
                backgroundColor: '#eaf5fe',
                // opacity: [0.3, 0.4, 0.3],
              },
            }}>
              <div>
                <Typography variant="h5" color="#000">ВЫБОР ПРАЧЕЧНОЙ</Typography>
                  <br/>

                  <FormControl sx={{ width:'100%' }}>
                    <Select
                      size='small'
                      value={selectedTockaId}
                      onChange={handleTockaChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      
                      {
                        typeof tockas != 'undefined' ?
                        tockas && tockas.map(tocka=>(
                                                
                                                  
                                                  
                          <MenuItem value={tocka.id}>{tocka.tocka_address}</MenuItem>
                        ))
                        : ''
                      }
                      

                      
                    </Select>
                    <FormHelperText>Выберите из выпадающего списка</FormHelperText>
                  </FormControl>
                    <br/><br/>
                    <Stack direction='row' spacing={2}>

                    <LoadingButton 
                      disableElevation={true} sx={{
                      width:'50%',
                      backgroundColor: '#66bb6a',
                      pt:1, pb:1,
                      '&:hover': {
                        backgroundColor: '#388e3c',
                        // opacity: [0.3, 0.4, 0.3],
                      },
                      }}
                      // onClick={}
                      loading={loading}
                      onClick={()=>{
                        setLoading(true)
                        // setTimeEditing(true)
                        fetchPromocodesByTocka(0)
                      //   setTimeout(function () {
                      //     setLoading(false)
                      //     // onClose();
                      // }, 200);

                        

                      }}
                      loadingPosition="center"
                      variant="contained"
                    ><span>ПОКАЗАТЬ</span>
                    </LoadingButton>

                      { 
  


                      admin.admin_type == 1 ? 
                        <LoadingButton disableElevation={true} sx={{
                          backgroundColor: 'primary.light',
                          width:'50%',
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            // opacity: [0.3, 0.4, 0.3],
                          },
                        }}
                          onClick={()=>{
                            
                            var yok = null;
                            console.log('BASDYN MY ' + open + ' sel ' + selectedPromocodeId);
                            setSelectedPromocodeId(0);
                            
                            setSelectedPromocode(yok);
                            setOpen(true);
                            console.log('BASDYN MY1 ' + open + ' sel ' + selectedPromocodeId);
                            
                            console.log('BASDYN MY2 ' + open);
                          }}
                          loading={loading}
                          
                          loadingPosition="center"
                          variant="contained"
                        ><span>ДОБАВИТЬ</span>
                        </LoadingButton>
                      :
                      
                      admin.admin_type == 4 ? 
                          <LoadingButton disableElevation={true} sx={{
                            backgroundColor: 'primary.light',
                            width:'50%',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              // opacity: [0.3, 0.4, 0.3],
                            },
                          }}
                            onClick={()=>{
                              
                              var yok = null;
                              console.log('BASDYN MY ' + open + ' sel ' + selectedPromocodeId);
                              setSelectedPromocodeId(0);
                              
                              setSelectedPromocode(yok);
                              setOpen(true);
                              console.log('BASDYN MY1 ' + open + ' sel ' + selectedPromocodeId);
                              
                              console.log('BASDYN MY2 ' + open);
                            }}
                            loading={loading}
                            
                            loadingPosition="center"
                            variant="contained"
                          ><span>ДОБАВИТЬ</span>
                          </LoadingButton>
                      
                      : 
                      
                      typeof selectedTocka.podpiska != 'undefined' ? 
                          selectedTocka.podpiska[1] == '1' ? 
                            admin.admin_type != 2 ?
                              <LoadingButton disableElevation={true} sx={{
                                  backgroundColor: 'primary.light',
                                  width:'50%',
                                  '&:hover': {
                                    backgroundColor: 'primary.main',
                                    // opacity: [0.3, 0.4, 0.3],
                                  },
                                }}
                                  onClick={()=>{
                                    
                                    var yok = null;
                                    console.log('BASDYN MY ' + open + ' sel ' + selectedPromocodeId);
                                    setSelectedPromocodeId(0);
                                    
                                    setSelectedPromocode(yok);
                                    setOpen(true);
                                    console.log('BASDYN MY1 ' + open + ' sel ' + selectedPromocodeId);
                                    
                                    console.log('BASDYN MY2 ' + open);
                                  }}
                                  loading={loading}
                                  
                                  loadingPosition="center"
                                  variant="contained"
                                ><span>ДОБАВИТЬ</span>
                                </LoadingButton>
                         : ''
                         : ''
                         : ''
                         }

                   


                    </Stack>

                    <Box >
					<FormControlLabel control={
					
					<Checkbox
                      defaultChecked
                      checked={justOwn}
                      onChange={(e)=>{
                        console.log('CHECK CHANGED ' + e.target.checked);
                        setJustOwn(e.target.checked)
                      }}
                      // inputProps={{ 'aria-label': 'controlled' }}
                    /> 
					} label="Показать только активные промокоды" />
                    
                    </Box>
                    
                </div>
          </Item2>
          <Item2 elevation='0' sx={{
                mt:1,
                height: '500',
                backgroundColor: '#eafefd',
                // opacity: [0.3, 0.4, 0.3],
                '&:hover': {
                  backgroundColor: '#eaf5fe',
                  // opacity: [0.3, 0.4, 0.3],
                },
              }}>
                <span></span>
              </Item2>
        </Grid>

            
            
          
        </Grid>
        <Grid item xs={12} sm={6} md={9} >
        <Item elevation='0' height ={1000}
              sx={{
                
                height: '1000',
                backgroundColor: '#eafefd',
                // opacity: [0.3, 0.4, 0.3],
                // '&:hover': {
                //   backgroundColor: 'primary.main',
                  // opacity: [0.3, 0.4, 0.3],
                // },
              }}
            >
            <Typography variant="h5" color="#000" sx={{m:1}} align='left'>СПИСОК ПРОМОКОДОВ</Typography>
            <div style={{ height: 350, width: '100%' }}>
            <StyledDataGrid  sx={{m:1}}  headerHeight={80}
                getRowClassName={(params) => `super-app-theme--${params.row.isactive == false ? 'Rejected':''}`} 
                // paginationMode="server"
                disableColumnMenu
                slots={{
                  loadingOverlay: LinearProgress,
                }}
                loading = {loading}
                height={1000}
                disableColumnFilter

				localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}

				// localeText={{
				// 	MuiTablePagination: {
				// 	labelDisplayedRows: ({ from, to, count }) =>
				// 		`${from} - ${to} из ${count}`,
				// 	},
				// }}

                onCellDoubleClick = {async (params, event) => {
                            //console.log('DOUBLE CLICKED ' + params.row.col1 + ' ' + params.row.col2.product_name + ' params.col4.product_id '  + ' ' + params.id);
                            
                            if (admin.admin_type == 1 || admin.admin_type == 4) {
                              setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
                              setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
                              setOpen(true);
                            } else {
                              if (typeof selectedTocka.podpiska != 'undefined' && selectedTocka.podpiska[1] == '1' && admin.admin_type != 2) {
                                setSelectedPromocode(promocodes.filter((prod)=> { return prod.id === params.id})[0])
                                setSelectedPromocodeId(promocodes.filter((prod)=> { return prod.id === params.id})[0].id)
                                setOpen(true);
                              }
                            }

                            
                              

                            if (!event.ctrlKey) {
                              event.defaultMuiPrevented = true;
                            }
                            }}

                // pagination
                rows={promocodes}
                {...promocodes}
                // rowCount={rowCountState}
                
                columns={columns}
                // pageSize={50}
                // onPageChange={(params) => {
                //   //console.log("===params===", params);
                // }}
                  // rowsPerPageOptions={[50]}
                  
                  // pageSizeOptions={[50]}
                  // paginationModel={paginationModel}
                  // paginationMode="server"
                  // onPaginationModelChange={(page)=>{
                  //   // //console.log('page ' + page.page);
                  //   setLoading(true);
                  //   setPaginationModel({ 'page' : page.page, pageSize: 50})
                  //   fetchPromocodesByTocka(page.page + 1)
                  //   setLoading(false);
                  // }}

            />

            </div>
            
            
          
          
          
          </Item>
                  
		</Grid>
      
      </Grid>

      </Box>
    
    </div>
  )
}

export default Settings;
